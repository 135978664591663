import React, { useEffect } from "react";

const App = () => {
  useEffect(() => {
    // Get the part of the URL after the domain.
    const pathname = window.location.pathname;

    // Extract userType from the pathname.
    const userType = pathname.split("/")[1]; // Assuming the URL is like localhost:3000/employee or localhost:3000/customer

    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    let redirectUrl = "";

    // Redirect based on device and userType.
    if (/android/i.test(userAgent)) {
      console.log("You are using Android.");
      redirectUrl =
        userType === "employee"
          ? "https://play.google.com/store/apps/details?id=com.symbri"
          : "https://play.google.com/store/apps/details?id=com.symbri.customer";
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      console.log("You are using iOS.");
      redirectUrl =
        userType === "employee"
          ? "https://apps.apple.com/us/app/symbri-employee/id6463662265"
          : "https://apps.apple.com/us/app/symbri-customer/id6463666469";
    } else {
      console.log("You are using a Desktop PC.");
      redirectUrl =
        userType === "employee"
          ? "https://app.symbri.com"
          : "https://app.symbri.com";
    }

    window.location.href = redirectUrl;
  }, []); // Empty dependency array means this useEffect runs once after the initial render

  return (
    <div>
      <h1>Redirecting...</h1>
    </div>
  );
};

export default App;
